<template>
  <div class="page">
    <div class="page__btns">
      <a-row>
        <a-col span="3">
          <a-button type="primary" @click="openAdd"> 新建标签 </a-button>
        </a-col>
        <a-col span="6">
          <a-input
            class="w220"
            placeholder="请输入标签查询"
            v-model="searchVal"
          ></a-input>
        </a-col>
        <a-col span="3">
          <a-button type="primary" @click="search"> 查询 </a-button>
        </a-col>
      </a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      bordered
      @change="changeTable"
    >
      <span slot="action" slot-scope="text, record">
        <div class="action_custom">
          <a-icon type="edit" @click="edit(record)" />
          <a-icon type="delete" @click="deleteHandle(record)" />
          <!-- <a-icon type="user-add" @click="add(record)" /> -->
        </div>
      </span>
    </a-table>
    <a-modal
      title="标签"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item ref="name" label="标签名" prop="name">
            <a-input
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "标签名",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    align: 'center'
  },
  {
    title: "用户数",
    dataIndex: "member_count",
    key: "member_count",
    align: 'center'
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];
export default {
  name: "MemberLabel",
  data() {
    return {
      dataSource: [],
      searchVal: "",
      visible: false,
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入标签名", trigger: "blur" }],
      },
      columns,
      page: 1,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
    };
  },
  created() {
    this.initTable();
  },
  computed: {
    params() {
      return { page: this.page, search: this.searchVal };
    },
  },
  methods: {
    search() {
      this.page = 1;
      this.initTable();
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/labels/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    openAdd() {
      this.visible = true;
      this.form = {
        name: "",
      };
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.form.id
            ? this.$axios.put(`/labels/${this.form.id}/`, this.form)
            : this.$axios.post("/labels/", this.form);
          request.then((res) => {
            console.log(res);
            this.visible = false;
            this.$message.success("操作成功");
            this.initTable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit(record) {
      console.log(record);
      this.visible = true;
      this.$axios.get(`/labels/${record.id}/`).then((res) => {
        console.log(res);
        this.form = res;
      });
    },
    deleteHandle(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/labels/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
